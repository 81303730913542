import { EnumToArray } from '../../../../../utils/enumToArray';

enum Type {
  Added = 'added',
  Confirm = 'confirm',
}

enum Mode {
  Add = 'add',
  Remove = 'remove',
}

enum Error {
  Increment = 'increment',
  Decrement = 'decrement',
  Delete = 'delete',
  Get = 'get',
}

enum Load {
  Update = 'update',
  Delete = 'delete',
  Get = 'get',
  GetDelete = 'getDelete',
}

export const PTZHeaderDrawerBagConfigList = {
  Type: EnumToArray([Type]),
  Load: EnumToArray([Load]),
  Error: EnumToArray([Error]),
  Mode: EnumToArray([Mode]),
};

export const PTZHeaderDrawerBagConfig = {
  Type,
  Load,
  Error,
  Mode
};
